$(document).ready(function () {
  $('#copy-btn').one('click', function (e) {
    e.preventDefault();
    var textToCopy = $('#token').text();
    const tempElement = $('<textarea>').val(textToCopy).appendTo('body').css('position', 'fixed').css('top', '0').css('left', '0').css('opacity', '0');
    tempElement.get(0).select();
    document.execCommand('copy');
    tempElement.remove();

    // navigator.clipboard.writeText(textToCopy);
    window.location.href = '/app_links';
  });
});
